<template>
    <div>
        <q-table
            title="Utilisateurs"
            :loading="loading"
            :rows="rows"
            :columns="columns"
            row-key="uuid"
            flat
            :rows-per-page-options="[10, 25]"
        >
            <template v-slot:top="props">
                <q-toggle v-model="subscribedOnly" label="Cotisation à jour" />
                <q-space />
                <q-btn icon="refresh" @click="fetch" />
            </template>
            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td key="username" :props="props">
                        {{ props.row.username }}
                    </q-td>
                    <q-td key="first_name" :props="props">
                        {{ props.row.first_name }}
                        <q-popup-edit v-model="props.row.first_name" @hide="updateUser(props.row)" auto-save>
                            <q-input v-model="props.row.first_name" autofocus dense @keyup.enter="updateUser(props.row)" />
                        </q-popup-edit>
                    </q-td>
                    <q-td key="last_name" :props="props">
                        {{ props.row.last_name }}
                        <q-popup-edit v-model="props.row.last_name" @hide="updateUser(props.row)" auto-save>
                            <q-input v-model="props.row.last_name" autofocus dense @keyup.enter="updateUser(props.row)" />
                        </q-popup-edit>
                    </q-td>
                    <q-td key="email" :props="props">
                        {{ props.row.email }}
                        <q-popup-edit v-model="props.row.email" @hide="updateUser(props.row)" auto-save>
                            <q-input v-model="props.row.email" autofocus dense @keyup.enter="updateUser(props.row)" />
                        </q-popup-edit>
                    </q-td>
                    <q-td key="subscription_year" :props="props">
                        {{ props.row.subscription_year }}
                        <q-popup-edit v-model="props.row.subscription_year" @hide="updateUser(props.row)" auto-save>
                            <q-input v-model.number="props.row.subscription_year" autofocus dense @keyup.enter="updateUser(props.row)" />
                        </q-popup-edit>
                    </q-td>
                </q-tr>
            </template>
        </q-table>
    </div>
</template>

<script>
import { ref } from "vue";
import User from "@/models/user/user.js";
import { CrudFilter } from "@/utils/api.js";

export default {
    name: 'UserAdmin',
    data () {
        return {
            subscribedOnly: false,
            rows: [],
            columns: [{
                field: "username",
                name: "username",
                label: "Nom d'utilisateur",
                sortable: true
            }, {
                field: "first_name",
                name: "first_name",
                label: "Prénom",
                sortable: true
            }, {
                field: "last_name",
                name: "last_name",
                label: "Nom",
                sortable: true
            }, {
                field: "email",
                name: "email",
                label: "Adresse e-mail"
            }, {
                field: "subscription_year",
                name: "subscription_year",
                label: "Année de cotisation",
                sortable: true
            }],
            loading: true
        };
    },
    mounted () {
        this.fetch();
    },
    watch: {
        "subscribedOnly": "fetch"
    },
    methods: {
        async fetch () {
            this.loading = true;
            let filters = [];

            if (this.subscribedOnly) {
                filters.push(new CrudFilter("subscription_year", "eq", new Date().getFullYear()));
            }

            this.rows = ref(await User.filter(filters));
            this.loading = false;
        },
        async updateUser (user) {
            this.loading = true;
            await user.update();
            this.loading = false;
        }
    }
}
</script>
